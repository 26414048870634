<template>
    <div class="muzoo-codebox">
        <div class="mrmr">
            <codemirror v-model="sourceCode" :options="cmOptions" @ready="onCmReady">
            </codemirror>
        </div>
        <div class="button-box">
            <button v-on:click="btnRun">Run</button>
            <button v-on:click="btnVisualize">Visualize</button>
            <button v-on:click="btnReset">Reset</button>
        </div>
        <div id="run-result" v-if="runResult">
            <h4>Run Results:</h4>
            <codemirror v-model="runResult" :options="cmRunResultsOptions" @ready="onCmReady" v-if="runResult">
            </codemirror>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { codemirror } from 'vue-codemirror'
    import 'codemirror/lib/codemirror.css'
    import 'codemirror/mode/python/python.js'
    import 'codemirror/theme/elegant.css'

    export default {
        name: "mzcodebox",
        components: {
            codemirror,
        },
        data() { return {
            cmOptions: {
                tabSize: 4,
                indentUnit: 4,
                styleActiveLine: true,
                lineNumbers: true,
                line: true,
                mode: 'text/x-python',
                theme: 'elegant'
            },
            cmRunResultsOptions: {
                tabSize: 4,
                readOnly: true,
                mode: 'text/plain',
            },
            sourceCode: '',
            originalCode: '',
            runResult: null,
        }},
        methods: {
            onCmReady(cm) {
                cm.setSize(null, "auto");
            },
            btnReset() {
                this.sourceCode = this.originalCode;
            },
            btnVisualize() {
                let baseUrl = "http://www.pythontutor.com/visualize.html";
                let srcCode = encodeURIComponent(this.sourceCode);
                window.open(`${baseUrl}#code=${srcCode}&cumulative=false&curInstr=0&heapPrimitives=nevernest&mode=display`)
            },
            btnRun() {
                let runResult = Vue.prototype.$python.runPythonCode(this.sourceCode);
                this.runResult = runResult;
            }
        },
        mounted() {
            let defaultSlot = this.$slots['default'];

            if (defaultSlot && defaultSlot.length>0) {
                let top = defaultSlot[0];
                if (top.children && top.tag && top.tag === 'pre')
                    this.originalCode = top.children[0].text;
                else
                    this.originalCode = top.text;
                this.sourceCode = this.originalCode
            }
        }
    }


</script>
<style scoped>
    .muzoo-codebox {
        background: #ddd;
        padding: 5px;
    }
    .button-box {
        margin-top: 5px;
    }
    button {
        border: solid grey;
        background: #ccc;
        color: black;
        padding: 1px 6px;
        margin-left: 5px;
        margin-right: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 0.8rem;
    }
    .mrmr {min-height: 2.5em;}
</style>